import React, {useEffect, useState} from 'react';
import style from './Auth.module.css'
import {useNavigate} from "react-router-dom";
import {isAuthenticated} from "../additional/AuthService";

const Auth = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Get the navigate function
    const domain = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        const checkAuth = async () => {
            const authenticated = await isAuthenticated();
            if (authenticated) {
                navigate('/dashboard'); // Redirect to the dashboard if the user is authenticated
            }
        };
        checkAuth();
    }, [navigate]);

    // const apiKey = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_CLIENT_PASSWORD_ID;
    const client_secret = process.env.REACT_APP_CLIENT_PASSWORD_SECRET;
    const grant_type = 'password';

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${domain}/auth/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username, password, grant_type, client_id, client_secret
                }),
            });

            const data = await response.json();

            if (response.ok) {
                // If login is successful, store the tokens in local storage
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                console.log('Logged in successfully!');

                // create new client logentry
                await fetch(`${domain}/api/users/logentry/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        access_token: data.access_token
                    }),
                });

                // Redirect to the homepage after successful login
                navigate('/dashboard');
            } else {
                console.error('Login failed. Please check your credentials.');
                // TODO: handle error
            }

            // let access_token = localStorage.getItem('access_token');
            // let refresh_token = localStorage.getItem('refresh_token');
            //
            // console.log('Have tokens...');
            // console.log('access_token: ' + access_token);
            // console.log('refresh_token: ' + refresh_token);
            //
            // if (access_token && refresh_token) {  // TODO: remove from here...
            //     const responseUser = await fetch('http://127.0.0.1:8000/api/users/get_user/', {
            //         method: 'GET',
            //         headers: {
            //             'Content-Type': 'application/json',
            //             'Authorization': `Bearer ${access_token}`
            //         }
            //     });
            //
            //     const userData = await responseUser.json();
            //     console.log(userData);
            // }else{
            //     console.log('No tokens')
            // }

        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return(
        <div className={style.login_box}>
            <h2>Login</h2>
            <form>
                <div className={style.user_box}>
                    <input
                        type="text"
                        name=""
                        required=""
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <label>Username</label>
                </div>
                <div className={style.user_box}>
                    <input
                        type="password"
                        name=""
                        required=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <label>Password</label>
                </div>
                <a href='' onClick={handleLogin}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Submit
                </a>
            </form>
        </div>
    )
};


export default Auth;
