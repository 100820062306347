import React from 'react';
import styles from './Tables.module.scss';
import {useEffect} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import RotatingIcon from "../additional/RotatingIcon";


const Cosmos = ({user, domain, ...props}) => {
    const [cosmos, setCosmos] = React.useState([]);
    const [ordering, setOrdering] = React.useState([]);

    const trueImagePath = 'https://uanodes.com.ua/static/admin/img/icon-yes.svg';
    const falseImagePath = 'https://uanodes.com.ua/static/admin/img/icon-no.svg';
    const unknownImagePath = 'https://uanodes.com.ua/static/admin/img/icon-unknown.svg';
    const getImagePath = (value) => {
        return value !== null ? (value ? trueImagePath : falseImagePath) : unknownImagePath;
    };

    useEffect(() => {
        let tables = document.getElementById('tables_1');
        let header = document.getElementsByTagName('header')[0];
        header.style.borderBottom = '0';

        function handleTablesScroll(){
            if (tables.scrollTop > 30) {
                header.style.borderBottom = '1px solid rgba(86, 92, 114, 0.44)';
            }else{
                header.style.borderBottom = '0';
            }
        }
        // Add the event listener when the component mounts
        if (tables) {
            tables.addEventListener('scroll', handleTablesScroll);
        }
        return () => {
            if (tables) {
                tables.removeEventListener('scroll', handleTablesScroll);
            }
        };
    }, []); // Empty dependency array ensures the effect runs only once during mount
    
    useEffect(() => {
        const access_token = localStorage.getItem('access_token');

        const fetchCosmosData = () => {
            fetch(`${domain}/api/core/nodes_clients?o=${ordering}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`,
                },
            })
                .then(response => {
                    const json = response.json()
 
                    return json
                })
                .then(data => {
                    let results = data['results']
                    setCosmos(results)
                })
        }
        fetchCosmosData()
      
    }, [ordering]);

    const columnKeys = cosmos && cosmos.length > 0 ? Object.keys(cosmos[0]) : [];

    const handleOrdering = (event) => {
        const ordering_handler = event.target;
        const value = ordering_handler.innerText.trim();

        setOrdering((prevOrdering) => {
            // if (!['df', 'ad', 'ar'].includes(value)) {
            const isValueWithMinus = prevOrdering.includes(`-${value}`);
            const isValueWithoutMinus = prevOrdering.includes(value);
            const sortOptionsElement = document.querySelector(`[data-key="${value}"]`);
            sortOptionsElement.style.display = 'block';

            if (isValueWithMinus) {
                return prevOrdering.map((column) => (column === `-${value}` ? value : column));
            } else if (isValueWithoutMinus) {
                return prevOrdering.map((column) => (column === value ? `-${value}` : column));
            } else {
                return [value, ...prevOrdering];
            }
            // }
            // return prevOrdering;
        });
    };

    const handleRemoveOrdering = (key) => {
        let k = ordering.indexOf(key)+1 ? ordering.indexOf(key) : ordering.indexOf('-'+key);
        const value = ordering[k];

        setOrdering((prevOrdering) => {
            const sortOptionsElement = document.querySelector(`[data-key="${key}"]`);
            sortOptionsElement.style.display = 'none';
            return prevOrdering.filter((column) => (column !== value && column !== `-${value}`));
        });
    }

    return (
        <div id="tables_1" className={styles.tables + ' tables'}>
            <table>
                <thead>
                <tr>
                    {columnKeys.map((key, index) => (
                        <th className={styles.ordering} key={index}>
                            <div className={styles.ordering_block}>
                                <div className="ordering_handler" title="Toggle ordering" onClick={handleOrdering} style={{alignSelf: 'center'}}>{key}</div>
                                <div className={styles.sortoptions} data-key={key}>
                        <span className={styles.sortremove} title="Remove from sorting" onClick={() => handleRemoveOrdering(key)}>
                            <ClearIcon style={{fontSize: '12px', display: 'inline-block', verticalAlign: 'middle'}}/>
                        </span>
                        <span className={styles.sortpriority} title="Sorting priority">{(ordering.indexOf(key)+1) || (ordering.indexOf('-'+key)+1)}</span>
                        <span className={styles.toggle_ordering}>
                            <RotatingIcon
                                isRotated={ordering.includes(`-${key}`)}
                                onClick={() => handleOrdering(key)}
                            />
                        </span>
                                </div>
                            </div>
                        </th>
                    ))}

                </tr>
                </thead>
                {cosmos && cosmos.length > 0 && (
                    <tbody>
                     {cosmos.map((item, rowIndex) => (
                    <tr key={rowIndex}>
                        {columnKeys.map((key, colIndex) => (
                        <td key={colIndex}>
                        {['boolean', 'object'].includes(typeof item[key]) ? (
                            <img src={getImagePath(item[key])} alt={key} />
                          ) : (
                            item[key].toString()
                          )}
                          </td>
                        ))}
                    </tr>
                        ))}
                    </tbody>
                )}
            </table>
            {!cosmos && (
                <h3 style={{textAlign: "center", marginTop: '100px'}}>You do not have any Nodes data yet.</h3>
            )}
        </div>

    );
};

export default Cosmos;
