import React from "react";


export const isAuthenticated = () => {
    // Check if the user is authenticated based on your authentication mechanism.
    let access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');
    const domain = process.env.REACT_APP_API_ENDPOINT;

    const refreshToken = async () => {
        try {
            const response = await fetch(`${domain}/auth/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: process.env.REACT_APP_CLIENT_PASSWORD_ID,
                    client_secret: process.env.REACT_APP_CLIENT_PASSWORD_SECRET,
                    grant_type: 'refresh_token',
                    refresh_token,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                // Update the tokens in local storage
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                access_token = data.access_token;
                return access_token;
            } else {
                // Refresh token request failed, so the user is not authenticated.
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('access_token');
                return Promise.reject(new Error('Refresh token request failed'));
            }
        } catch (error) {
            console.error('Error while refreshing tokens:', error);
            return Promise.reject(error);
        }
    };

    if (!access_token) {
        // Access token is missing, so check if the refresh token exists.
        if (refresh_token) {
            // Attempt to refresh the tokens using the refresh token.
            return refreshToken()
                .then((token) => {
                    console.log('Access token refreshed:', token);
                    return true;
                })
                .catch((error) => {
                    console.error('Error while refreshing tokens:', error);
                    return false;
                });
        } else {
            // Both access token and refresh token are missing, so the user is not authenticated.
            return Promise.resolve(false);
        }
    }

    // At this point, we have an access token (either from local storage or refreshed).
    // Now, attempt to fetch user data using the access token.
    return fetch(`${domain}/api/users/get_user/`, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    })
        .then((response) => {
            if (response.status === 401) {
                return refreshToken().then(() => true).catch(() => false);
            }
            return true;
        })
        .catch((error) => {
            // if (error.message === '401 Unauthorized') {
            //     // Handle the 401 Unauthorized error here (e.g., redirect to login page)
            //     return Promise.resolve(false);
            // }
            console.error('Error while fetching user data:', error);
            return false;
        });

};


// export const logout = () => {
//     const navigate = useNavigate();
//     // Clear authentication tokens and perform any additional cleanup required.
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('refresh_token');
//     navigate('/')
// };





// export const isAuthenticated = () => {
//     // Check if the user is authenticated based on your authentication mechanism.
//     let access_token = localStorage.getItem('access_token');
//     let refresh_token = localStorage.getItem('refresh_token');
//
//     if (!access_token) {
//         // Access token is missing, so check if the refresh token exists.
//         if (refresh_token) {
//             // Attempt to refresh the tokens using the refresh token.
//             return fetch('http://127.0.0.1:8000/auth/token/', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     client_id: process.env.REACT_APP_CLIENT_PASSWORD_ID,
//                     client_secret: process.env.REACT_APP_CLIENT_PASSWORD_SECRET,
//                     grant_type: 'refresh_token',
//                     refresh_token,
//                 }),
//             })
//                 .then((response) => {
//                     if (response.ok) {
//                         return response.json();
//                     } else {
//                         // Refresh token request failed, so the user is not authenticated.
//                         localStorage.removeItem('refresh_token');
//                         localStorage.removeItem('access_token');
//                         return Promise.reject(new Error('Refresh token request failed'));
//                     }
//                 })
//                 .then((data) => {
//                     // Update the tokens in local storage
//                     localStorage.setItem('access_token', data.access_token);
//                     localStorage.setItem('refresh_token', data.refresh_token);
//                     access_token = data.access_token;
//                     console.log(access_token);
//                     return access_token;
//                 })
//                 .catch((error) => {
//                     console.error('Error while refreshing tokens:', error);
//                     return false;
//                 });
//         } else {
//             // Both access token and refresh token are missing, so the user is not authenticated.
//             return Promise.resolve(false);
//         }
//     }
//
//     // At this point, we have an access token (either from local storage or refreshed).
//     // Now, attempt to fetch user data using the access token.
//     return fetch('http://127.0.0.1:8000/api/users/get_user/', {
//         headers: {
//             Authorization: `Bearer ${access_token}`,
//         },
//     })
//         .then((response) => response.ok)
//         .catch((error) => {
//             console.error('Error while fetching user data:', error);
//             return false;
//         });
// };


// export const isAuthenticated = async () => {
//     // Check if the user is authenticated based on your authentication mechanism.
//     let access_token = localStorage.getItem('access_token');
//     let refresh_token = localStorage.getItem('refresh_token');
//
//     if (!access_token) {
//         // Access token is missing, so check if the refresh token exists.
//         if (refresh_token) {
//             // Attempt to refresh the tokens using the refresh token.
//             try {
//                 const requestBody = JSON.stringify({
//                     client_id: process.env.REACT_APP_CLIENT_PASSWORD_ID,
//                     client_secret: process.env.REACT_APP_CLIENT_PASSWORD_SECRET,
//                     grant_type: 'refresh_token',
//                     refresh_token,
//                 });
//
//                 const response = await fetch('http://127.0.0.1:8000/auth/token/', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: requestBody,
//                 });
//
//                 if (response.ok) {
//                     const data = await response.json();
//                     // Update the tokens in local storage
//                     localStorage.setItem('access_token', data.access_token);
//                     localStorage.setItem('refresh_token', data.refresh_token);
//                     access_token = data.access_token;
//                 } else {
//                     // Refresh token request failed, so the user is not authenticated.
//                     return false;
//                 }
//             } catch (error) {
//                 console.error('Error while refreshing tokens:', error);
//                 return false;
//             }
//         } else {
//             // Both access token and refresh token are missing, so the user is not authenticated.
//             return false;
//         }
//     }
//
//     // At this point, we have an access token (either from local storage or refreshed).
//     // Now, attempt to fetch user data using the access token.
//     try {
//         const response = await fetch('http://127.0.0.1:8000/api/users/get_user/', {
//             headers: {
//                 Authorization: `Bearer ${access_token}`,
//             },
//         });
//
//         // If the response is OK (status code 200), the user is authenticated.
//         return response.ok;
//     } catch (error) {
//         console.error('Error while fetching user data:', error);
//         return false;
//     }
// };