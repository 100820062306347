import React from 'react';
import styles from './SidebarLinks.module.css'

const SidebarLinks = ({...props}) => {
    return (
        <div className={styles.links}>
            <div className={styles.link}>
                <img src="/telegram.ico" alt="telegram_bot" onClick={() => window.open(props.chat, '_blank')}/>
                <span className={styles.text}>Telegram</span>
            </div>
            <div className={styles.link}>
                <img src="/google_drive_colored.ico" alt="google_drive" onClick={() => window.open(props.google_drive, '_blank')}/>
                <span className={styles.text}>Google Drive</span>
            </div>
        </div>
    );
};

export default SidebarLinks;