import React from "react";
import styles from "./Dashboard.module.css";
import CircularProgress from '@mui/joy/CircularProgress';


function Dashboard({user}) {

    const getColorForValue = (value) => {
        if (value > 90) return "#006700";
        if (value >= 80) return "#d3b301";
        if (value >= 70) return "#ff7700";
        return "#a20101";
    };

    return (
        <div className={styles.container}>
            <div className="block_for_text">
                <h1 className={styles.dashboard}>Dashboard</h1>
                <span className={styles.welcome}>Welcome, {user?.name}!</span>
            </div>
            <div className={styles.progress_block}>
                {user?.servers_up && (
                    <div className={styles.servers}>
                        <h2 className={styles.title}>Servers</h2>
                        <div className={styles.progress_servers}>

                            <CircularProgress
                                determinate={false}
                                size="lg"
                                value={user?.servers_up?.percent}
                                variant="plain"

                                sx={{
                                    "--CircularProgress-progressThickness": "25px",
                                    '--CircularProgress-size': '250px',
                                    '--CircularProgress-progressColor': getColorForValue(user?.servers_up?.percent),
                                    '@media (max-width: 1200px)': {
                                        '--CircularProgress-size': '150px',
                                        "--CircularProgress-progressThickness": "15px",
                                    }
                                }}

                            ><p>{user?.servers_up?.percent}%</p></CircularProgress>
                            <p className={styles.description}>Number of active servers: {user?.servers_up?.active}/{user?.servers_up?.all}</p>
                        </div>
                    </div>
                )}
                {user?.nodes_up && (
                    <div className={styles.nodes}>
                        <h2 className={styles.title}>Nodes</h2>
                        <div className={styles.progress_servers}>

                            <CircularProgress
                                determinate={false}
                                size="lg"
                                value={user?.nodes_up?.percent}
                                variant="plain"

                                sx={{
                                    "--CircularProgress-progressThickness": "25px",
                                    '--CircularProgress-size': '250px',
                                    '--CircularProgress-progressColor': getColorForValue(user?.nodes_up?.percent),
                                    '@media (max-width: 1200px)': {
                                        '--CircularProgress-size': '150px',
                                        "--CircularProgress-progressThickness": "15px",

                                    }
                                }}

                            ><p>{user?.nodes_up?.percent}%</p></CircularProgress>
                            <p className={styles.description}>Number of active nodes: {user?.nodes_up?.active}/{user?.nodes_up?.all}</p>
                        </div>
                    </div>
                )}
                {user?.services_up && (
                    <div className={styles.services}>
                        <h2 className={styles.title}>Services</h2>
                        <div className={styles.progress_servers}>

                            <CircularProgress
                                determinate={false}
                                size="lg"
                                value={user?.services_up?.percent}
                                variant="plain"

                                sx={{
                                    "--CircularProgress-progressThickness": "25px",
                                    '--CircularProgress-size': '250px',
                                    '--CircularProgress-progressColor': getColorForValue(user?.services_up?.percent),
                                    '@media (max-width: 1200px)': {
                                        '--CircularProgress-size': '150px',
                                        "--CircularProgress-progressThickness": "15px",

                                    }
                                }}

                            ><p>{user?.services_up?.percent}%</p></CircularProgress>
                            <p className={styles.description}>Number of active services: {user?.services_up?.active}/{user?.services_up?.all}</p>
                        </div>
                    </div>
                )}
                {(!user?.servers_up && !user?.services_up && !user?.nodes_up) && (
                    <h2>You do not have any monitoring data yet.</h2>
                    )
                }

            </div>
        </div>
    )
}

export default Dashboard;