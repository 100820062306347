import Routers from "./components/Routers";
import { BrowserRouter } from "react-router-dom";
import {useEffect} from "react";

function App() {
    useEffect(() => {
        import("./utils/backgroundScript").then((backgroundScript) => {
            backgroundScript.default()
        })
    }, [])

    return (
        <BrowserRouter>
            <Routers/>
        </BrowserRouter>
    );
}

export default App;
