import React from 'react';
import style from './NotFound.module.scss'

const NotFound = () => {
    return (
        <div className={style.not__found}>
            <div className={style.notfound}>
                <div className={style.notfound_404}>
                    <h1>404</h1>
                    <h2>Page not found</h2>
                </div>
                <a href="/dashboard">Homepage</a>
            </div>
        </div>
    );
};

export default NotFound;
