import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from './AuthService';

const withAuthentication = (WrappedComponent) => {
    const EnhancedComponent = (props) => {
        const navigate = useNavigate();

        useEffect(() => {
            const checkAuth = async () => {
                const authenticated = await isAuthenticated();
                if (!authenticated) {
                    navigate('/login');
                }
            };
            checkAuth();
        }, [navigate]);

        return <WrappedComponent {...props} />;
    };

    return EnhancedComponent;
};

export default withAuthentication;