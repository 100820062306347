
import React from 'react';
import EjectIcon from '@mui/icons-material/Eject';
import styles from './RotatingIcon.module.css';

const RotatingIcon = ({ isRotated, onClick }) => {
    return (
        <EjectIcon
            className={`${styles.eject_icon} ${isRotated ? styles.rotated : ''}`}
            onClick={onClick}
        />
    );
};

export default RotatingIcon;

// import React, { useState } from 'react';
// import EjectIcon from '@mui/icons-material/Eject';
// import styles from './RotatingIcon.module.css'; // Replace with the actual path to your CSS file
//
// const RotatingIcon = ({ordering, value, ...props}) => {
//     const [isRotated, setIsRotated] = useState(false);
//
//     const handleRotateClick = () => {
//         setIsRotated(!isRotated);
//     };
//
//     return (
//         <EjectIcon
//             className={`${styles.eject_icon} ${isRotated ? styles.rotated : ''}`}
//             onClick={handleRotateClick}
//         />
//     );
// };
//
// export default RotatingIcon;
