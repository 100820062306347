import {useNavigate} from "react-router-dom";
import styles from "../../Sidebar/Sidebar.module.css";


const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear authentication tokens and perform any additional cleanup required.
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        navigate('/'); // Redirect to the proper URL (root path in this case) after logout.
    };

    // You can trigger the logout functionality using a button or any other event.
    return (
        <div className={styles.settings_menu} onClick={handleLogout}>
            <img src="/logout.svg" alt="Logout" />
            <span>Logout</span>
        </div>
    );
};

export default Logout;