import React, {useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiPaper from '@mui/material/Paper';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Link,
    useLocation
  } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styles from './Sidebar.module.css';
import Servers from '../Tables/Servers';
import Services from '../Tables/Services';
import Cosmos from '../Tables/Cosmos';
import Dashboard from '../Dashboard';
import { Routes, Route } from 'react-router-dom'
import withAuthentication from "../additional/withAuthentication";
import Logout from "../additional/Logout";
import SidebarLinks from "../SidebarLinks";

const drawerWidth = 240;
const closedWidth = 70;



const openedMixin = (theme) => ({
    
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closedWidth
    // width: `calc(${theme.spacing(7)})`, /* + 1px*/
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(8)})`, /* + 1px*/
    // },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...((open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }) || {
        // marginLeft: drawerWidth,
        width: `calc(100% - ${closedWidth}px)`,
    }),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        // backgroundColor: '#1e1e2f',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
            
            
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const MiniDrawer = () => {
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    const [isMenuOpen, setMenuOpen] =  React.useState(false);
    const [user, setUser] = React.useState({});
    const domain = process.env.REACT_APP_API_ENDPOINT;

    const Nav = [
        { id: 0, title: 'Dashboard', url: '/dashboard', img: '/dashboard.svg' },
        { id: 1, title: 'Servers', url: '/dashboard/servers', img: '/servers.svg' },
        { id: 2, title: 'Nodes', url: '/dashboard/cosmos', img: '/cosmos.svg' },
        { id: 3, title: 'Services', url: '/dashboard/services', img: '/services2.png' },
      ];

    useEffect(() => {
        const access_token = localStorage.getItem('access_token');

        const fetchUserData = () => {
            fetch(`${domain}/api/users/get_user/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Api-Key xzFu0WoN.XXv4vU8GR7GYH9vxibmGAXIOkmePHJuh',
                    'Authorization': `Bearer ${access_token}`,
                },
            })
                .then(response => {
                    const json = response.json()

                    return json
                })
                .then(data => {
                    setUser(data)
                })
        }

        fetchUserData()

    }, []);

    // useEffect(() => {
    //     // let tables = document.getElementById('tables_1');
    //     let tables = document.getElementsByClassName('tables');
    //     console.log(tables.length);
    //     let header = document.getElementsByTagName('header')[0];
    //     header.style.borderBottom = '0';
    //
    //     function handleTablesScroll(){
    //         for (let table of tables) {
    //             if (table.scrollTop > 30) {
    //                 header.style.borderBottom = '1px solid rgba(86, 92, 114, 0.44)';
    //             }else{
    //                 header.style.borderBottom = '0';
    //             }
    //         }
    //     }
    //     // Add the event listener when the component mounts
    //     for (let table of tables) {
    //         if (table) {
    //             table.addEventListener('scroll', handleTablesScroll);
    //         }
    //     }
    //     return () => {
    //         for (let table of tables) {
    //             if (table) {
    //                 table.removeEventListener('scroll', handleTablesScroll);
    //             }
    //         }
    //     };
    // }, []); // Empty dependency array ensures the effect runs only once during mount

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleMenuToggle = () => {
        setMenuOpen(!isMenuOpen);
      };
    
    return (
        
            <Box sx={{ display: 'flex' }}>
          
            <CssBaseline />
            
            <AppBar position="fixed" open={open} style={{backgroundColor: "#1b2a47"}}>
            <div id="sidebar__border" className={styles.border} style={{display: open ? 'block' : 'none'}}></div>
                <Toolbar>
                  
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{
                            marginRight: 5 /*,
                            ...(open && { display: 'none' }),*/
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={styles.navBar}>
                        <img src="/user.png" alt="avatar" className={styles.settings} onClick={handleMenuToggle} />
                        {isMenuOpen && (
                        <div className={styles.menu}>
                            {/*<div className={styles.settings_menu}>*/}
                            {/*    <img src="/setings.png" alt="Settings" />*/}
                            {/*    <span>Settings</span>*/}
                            {/*</div>*/}
                            <Logout/>
                        </div>
                    )}
                    </div>
                    {/* <div className={styles.search}>
                            <input type="search" placeholder={'Search for anything...'} className={styles.input_search}/>
                            <img className={styles.search_img} src="/search.png"/>
                       </div> */}

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <MuiPaper style={{backgroundColor: "#1b2a47", height: "100%", overflow: "hidden",  borderRight: open ? '1px solid rgb(111 111 111 / 14%)' : 'none' }}>
                <DrawerHeader>
           
                    <div className={styles.logo}>
                      <img className={styles.logo_img} src='/logo.jpg'
                      style={{width: open ?  "20%" : "100%"}}/>
                      <span className={styles.logo_name} 
                        style={{display: open ?  "block" : "none"}}
                      >UA NODES</span>
                  </div>
                </DrawerHeader>
             
                <Divider>
                    <div className={styles.border_profile} style={{display: open ? 'block' : 'none'}}></div>
                    { open &&
                  <div className={styles.profile}>
                    <img className={styles.profile_img} src='/user.png'/>
                    <span className={styles.name}>
                        {user?.name}
                    </span>
                    {/*<span className={styles.check}>$3000</span>*/}
                  </div>}
                </Divider>
                <List>
                    <div className={styles.main}>
                        <span style={{fontSize: '13px', marginLeft: '35px', color: '#8d8d8d', display: open ? 'block' : 'none'}}>Main</span>
                    {Nav.map((item, index) => (
                        <Link key={item.id} to={item.url} style={{textDecoration: 'none'}}>
                        <ListItem key={item.id} disablePadding sx={{ display: 'flex', color: "white", backgroundColor: location.pathname === item.url ? "#abbff31c" : "tranparent" }}>
                            <img className={styles.nav_img} key={item.id} src={item.img}
                            style={{marginLeft: open ? '35px' : '23px'}}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    
                                    // color: "white",
                                  
                                }}
                            />  
                                <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItem>
                        </Link>
                    ))}
                   </div> 
                </List>
                <Divider />
                    <SidebarLinks chat={user?.chat} google_drive={user?.google_drive}/>
                </MuiPaper>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Routes>
                    <Route path='/' element={<Dashboard user={user} domain={domain}/>}/>
                    <Route path='servers'  element={<Servers user={user} domain={domain}/>}/>
                    <Route path='services'  element={<Services user={user} domain={domain}/>}/>
                    <Route path='cosmos'  element={<Cosmos user={user} domain={domain}/>}/>
                </Routes>
            </Box>

        </Box>

    );
}

export default withAuthentication(MiniDrawer);