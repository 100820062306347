import React from "react";
import {Route, Routes, useLocation, Navigate} from "react-router-dom";
import MiniDrawer from "./Sidebar";
import Auth from "./Auth";
import NotFound from "./additional/NotFound/NotFound";

const allowedDashboardRoutes = [
    '/dashboard',
    '/dashboard/cosmos',
    '/dashboard/services',
    '/dashboard/servers',
];

function DashboardRoutes() {
    const location = useLocation();

    // Check if the current location matches any of the allowed dashboard routes
    const isDashboardRoute = allowedDashboardRoutes.some((route) =>
        location.pathname === route || location.pathname === route+'/'
    );

    return isDashboardRoute ? <MiniDrawer /> : <NotFound/>;
}

function Routers() {
    return (
        <Routes>
            <Route exact path="/" element={<Navigate to="/dashboard"/>}/>
            <Route path="/dashboard/*" element={ <DashboardRoutes/> }/>
            <Route path='/login' element={<Auth/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}

export default Routers;